body, *{
  font-family: "Lora", serif;
  font-weight: 400;
  font-style: normal;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Times New Roman', Times, serif;

}
